import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Card } from '../../../Card';
import styles from './cardList.module.sass';
import { StorageManager } from '../../../../lib/StorageManager';
import { Image } from '../../../Image/Image';

dayjs.extend(advancedFormat);

// Enum для типов круиза
export const CruiseType = {
  Rivers: 'rivers',
  Sea: 'sea',
};

const checkIsViewed = id => {
  const viewed = StorageManager.get('viewed') ?? [];

  if (viewed.length > 0) {
    return viewed.some(i => +i === +id);
  }
  return false;
};
export const CardList = ({
  popdir,
  cruises,
  isMobile,
  isShoping,
  isSea,
  urlBanners,
}) => (
  <>
    <div>
      {!isShoping ? (
        <div>
          <div className={styles.cardList}>
            {cruises.slice(0, 8).map((i, index) => (
              <Card
                index={index}
                id={i.id}
                category={i.category_id}
                categoryTitle={i.category_title}
                popdir={popdir}
                start={i.kr_start_city}
                end={i.kr_finish_city}
                shipId={i.ship_id}
                title={i.t_title}
                key={i.id}
                route={i.route_full}
                places={i.free_places_true}
                images={i.route_img_new}
                startDate={i.kr_date_start}
                endDate={i.kr_date_end}
                days={i.kr_days}
                night={i.kr_nights}
                beforeRise={i.days_before_rise}
                price={i.min_price_discounted}
                oldPrice={i.min_price}
                link={i.id}
                cashback={i.cashback}
                discounts={i.explicit_discounts}
                specialNote={i.special_note}
                sea={isSea}
                viewed={checkIsViewed(i.id)}
                isMobile={isMobile}
                company={i.company}
                shopingBadge={i.shoppingdayBadge}
                blackFriday={i.black_friday}
                twelveDays={i.twelveDays}
                snowBadge={i.twelveDays}
                tour={i.tur_check}
                isSpecialOffer={i.special_offer}
                specialOfferValue={i.special_offer_value}
                turField={i.tur_field}
              />
            ))}
          </div>
          <div className={styles.innerBanner}>
            <a href={urlBanners?.page_url} target="_blank" rel="noreferrer">
              <Image
                src={`https://kruiz.online/images/previewActions/desktop/${urlBanners?.src}`}
                srcSet={`https://kruiz.online/images/previewActions/desktop/${urlBanners?.srcset}`}
              />
            </a>
          </div>
          <div className={styles.cardList}>
            {cruises.slice(8).map((i, index) => (
              <Card
                index={index}
                id={i.id}
                category={i.category_id}
                categoryTitle={i.category_title}
                popdir={popdir}
                start={i.kr_start_city}
                end={i.kr_finish_city}
                shipId={i.ship_id}
                title={i.t_title}
                key={i.id}
                route={i.route_full}
                places={i.free_places_true}
                images={i.route_img_new}
                startDate={i.kr_date_start}
                endDate={i.kr_date_end}
                days={i.kr_days}
                night={i.kr_nights}
                beforeRise={i.days_before_rise}
                price={i.min_price_discounted}
                oldPrice={i.min_price}
                link={i.id}
                cashback={i.cashback}
                discounts={i.explicit_discounts}
                specialNote={i.special_note}
                sea={isSea}
                viewed={checkIsViewed(i.id)}
                isMobile={isMobile}
                company={i.company}
                shopingBadge={i.shoppingdayBadge}
                blackFriday={i.black_friday}
                twelveDays={i.twelveDays}
                snowBadge={i.twelveDays}
                isSpecialOffer={i.special_offer}
                specialOfferValue={i.special_offer_value}
                turField={i.tur_field}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.cardPinkBorder}>
          <div className={styles.cardList}>
            {cruises.map((i, index) => (
              <Card
                index={index}
                id={i.id}
                category={i.category_id}
                categoryTitle={i.category_title}
                popdir={popdir}
                start={i.kr_start_city}
                end={i.kr_finish_city}
                shipId={i.ship_id}
                title={i.t_title}
                key={i.id}
                route={i.route_full}
                places={i.free_places_true}
                images={i.route_img}
                startDate={i.kr_date_start}
                endDate={i.kr_date_end}
                days={i.kr_days}
                night={i.kr_nights}
                beforeRise={i.days_before_rise}
                price={i.min_price_discounted}
                oldPrice={i.min_price}
                link={i.id}
                cashback={i.cashback}
                discounts={i.explicit_discounts}
                specialNote={i.special_note}
                sea={isSea}
                viewed={checkIsViewed(i.id)}
                isMobile={isMobile}
                company={i.company}
                shopingBadge={i.shoppingdayBadge}
                twelveDays={i.twelveDays}
                snowBadge={i.twelveDays}
                isSpecialOffer={i.special_offer}
                specialOfferValue={i.special_offer_value}
                turField={i.tur_field}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  </>
);
